"use client";
import React, { useEffect } from "react";

import { usePathname } from "next/navigation";
import { useAppSelector } from "@/lib/redux/hook";
import useAnalyticsTracker from "@/hooks/useAnalyticsTracker";
import { gtmInitilize, gtmPageView } from "@/utils/analytics-helper";
import { useAckeeGlobalTracker } from "@/hooks/useAckeeTracker";

const AnalyticsTrackerTrigger = () => {
  const user = useAppSelector((state) => state.user);
  const pathname = usePathname();

  useEffect(() => {
    if (user?.userAnalytics?.gtmId) {
      gtmInitilize(user?.userAnalytics?.gtmId);
      gtmPageView({ merchantName: user?.account?.name || "" });
    }
  }, [user]);

  useAnalyticsTracker();

  useAckeeGlobalTracker(pathname!, user?.ackeeAnalytics?.analyticDomainId);

  return <></>;
};

export default AnalyticsTrackerTrigger;
